.cart-button {
    position: fixed;
    top: 90px; /* Adjust this value to match the height of your navbar */
    right: 20px;
    padding: 5px 20px;
    background-color: #1D3A51;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    z-index: 1000;
  }
  
  .cart-card {
    position: fixed;
    top: 140px; /* Adjust this to leave space for the navbar and button */
    right: 20px;
    width: 500px;
    background-color: #F2C232;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    z-index: 1000;
  }
  
  .cart-card h3 {
    margin-top: 0;
  }
  
  .cart-card button {
    margin-top: 20px;
    padding: 5px 15px;
    background-color: #d9534f;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  