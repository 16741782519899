/* Homepage.css */

.header-style {
    color: #1D3A51;
    font-family: 'Druk Text', sans-serif;
    text-align: left;
    font-weight: 900;
    font-size: 48px;
  }
  
  .subheader-style {
    color: #F2C232;
    font-family: 'Druk Text', sans-serif;
    text-align: left;
    font-weight: 900;
    font-size: 35px;
  }
  
  /* Responsive font sizes for smaller screens */
  @media (max-width: 1080px) {
    .header-style {
      font-size: 20px;
      text-align: center;
    }
  
    .subheader-style {
      font-size: 20px;
      text-align: center;
    }
  }
  